<template>
  <div>
    <v-card>
      <v-card-text class="text-h5 px-4">
        <v-form
          ref="formPeriodClousre"
          v-model="validInvoice"
          lazy-validation
          :disabled="accessRight.length < 2 && accessRight.includes('show')"
        >
          <v-row>
            <v-col cols="12">
              <v-card class="mx-auto" flat>
                <v-card-title
                  class="py-1 primary--text d-flex justify-space-between"
                  ><span class="d-flex">
                    <v-icon color="primary">mdi-package-variant-closed-remove</v-icon>
                    <h6 class="ms-2 align-self-center font-weight-regular">
                      {{ $t("route.periodClosure") }}
                    </h6>
                  </span>
                  <v-btn
                    color="primary"
                    small
                    :loading="loading"
                    :disabled="!validInvoice"
                    @click="handelPeriodClosure"
                  >
                    {{ $t("contact.save") }}
                  </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table
                        dense
                        class="custom-table elevation-1 item-table overflow-x-auto"
                        fixed-header
                        height="600"
                        max-width="200"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th :style="{ width: '140px' }">
                                {{ $t("invoice.field.year") }}
                              </th>
                              <th :style="{ width: '150px' }">
                                {{ $t("invoice.from") }}
                              </th>
                              <th :style="{ width: '150px' }">
                                {{ $t("invoice.to") }}
                              </th>
                              <th :style="{ width: '150px' }">
                                {{ $t("invoice.tech") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.proforma") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.delivery") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.invoice") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.receiptTaxInvoice") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.creditInvoice") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.returnCertificate") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.receipt") }}
                              </th>
                              <th :style="{ width: '100px' }">
                                {{ $t("route.supplierInvoice") }}
                              </th>
                              <th class="text-center" :style="{ width: '100px' }">
                                {{ $t("receipt.action") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in periodClosureItems"
                              :key="index"
                              :class="index + 1 == periodClosureItems.length ? 'newRow' : ''"
                            >
                              <td>
                                <v-menu
                                  :ref="`yearDateMenu${index}`"
                                  v-model="item.yearDateMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="item.year"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="processedDocumentItems[index].formattedYear"
                                      v-bind="attrs"
                                      :rules="yearRequired"
                                      v-on="on"
                                      readonly
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="item.year"
                                    type="month"
                                    @change="$refs[`yearDateMenu${index}`][0].save(item.year)"
                                  />
                                </v-menu>
                              </td>
                              <td>
                                <v-menu
                                  :ref="`fromDateMenu${index}`"
                                  v-model="item.fromDateMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="item.from_date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="processedDocumentItems[index].formatted_from_date"
                                      :rules="fromDateRequired"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="item.from_date"
                                    @change="$refs[`fromDateMenu${index}`][0].save(item.from_date)"
                                  />
                                </v-menu>
                              </td>
                              <td>
                                <v-menu
                                  :ref="`untilDateMenu${index}`"
                                  v-model="item.untilDateMenu"
                                  :close-on-content-click="false"
                                  :return-value.sync="item.until_date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      dense
                                      hide-details="auto"
                                      v-model="processedDocumentItems[index].formatted_until_date"
                                      :rules="untilDateRequired"
                                      v-bind="attrs"
                                      v-on="on"
                                      readonly
                                    />
                                  </template>
                                  <v-date-picker
                                    v-model="item.until_date"
                                    :min="item.from_date ? item.from_date : null"
                                    type="date"
                                    @change="$refs[`untilDateMenu${index}`][0].save(item.until_date)"
                                  />
                                </v-menu>
                              </td>
                              <td>
                                <v-text-field
                                  v-model="item.tech"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.proforma_invoice"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.delivery"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.invoice"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.recipt_tax_invoice"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.credit_invoice"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.return_certificate"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.recipt"
                                />
                              </td>
                              <td>
                                <v-checkbox
                                  dense
                                  v-model="item.supplier_invoice"
                                />
                              </td>
                              <td class="text-center">
                                <v-row>
                                  <v-col cols="6" class="text-start">
                                    <v-btn
                                      icon
                                      color="primary"
                                      @click="addRow"
                                      small
                                      :disabled="accessRight.length < 2 && accessRight.includes('show')"
                                    >
                                      <v-icon color="primary">mdi-plus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="6" class="text-start">
                                    <v-btn
                                      icon
                                      color="error"
                                      @click="deleteRow(index, item)"
                                      small
                                      :disabled="accessRight.length < 2 && accessRight.includes('show')"
                                    >
                                      <v-icon color="error">mdi-delete-sweep-outline</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY } from "../../../utils";
export default {
  name: "PeriodClosure",
  data() {
    return {
      validInvoice: false,
      loading: false,
      periodClosureItems: [
        {
          year: null,
          from_date: null,
          until_date: null,
          tech: "",
          proforma_invoice: false,
          delivery: false,
          invoice: false,
          recipt_tax_invoice: false,
          credit_invoice: false,
          return_certificate: false,
          recipt: false,
          supplier_invoice: false,
        },
      ],
      yearDateMenu: false,
      fromDateMenu: false,
      untilDateMenu: false,
      formattedReceiptDate: "",
      processedDocumentItems: [
        {
          formattedYear: "",
          formatted_from_date: "",
          formatted_until_date: "",
        },
      ],
    };
  },
  watch: {
    periodClosureItems: {
      handler(val) {
        this.processedDocumentItems = val.map((e) => ({
          ...e,
          formattedYear: e.year ? e.year.split("-")[0] : e.year,
          formatted_from_date: this.profileById.date_format
            ? formatDateDDMMYYYY(e.from_date ? e.from_date.split("T")[0] : e.from_date)
            : e.from_date ? e.from_date.split("T")[0] : e.from_date,
          formatted_until_date: this.profileById.date_format
            ? formatDateDDMMYYYY(e.until_date ? e.until_date.split("T")[0] : e.until_date)
            : e.until_date ? e.until_date.split("T")[0] : e.until_date,
        }));
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('periodClosure/GetPeriodClosure').then((res) => {
      if(res.length) {
        this.periodClosureItems = JSON.parse(JSON.stringify(res));
      }
    })
  },
  computed: {
    ...mapGetters({
      periodClouserList: "periodClosure/periodClouserList",
      accessRight: "accessRight",
      profileById: "profile/profileById",
    }),
    untilDateRequired() {
      return [
        (v) => !!v || this.$t("formRules.untilDateRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.untilDateRequired"),
      ];
    },
    fromDateRequired() {
      return [
        (v) => !!v || this.$t("formRules.fromDateRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.fromDateRequired"),
      ];
    },
    yearRequired() {
      return [
        (v) => !!v || this.$t("formRules.yearRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.yearRequired"),
      ];
    },
  },
  methods: {
    addRow() {
      this.periodClosureItems.push({
        year: null,
        from_date: null,
        until_date: null,
        tech: "",
        proforma_invoice: false,
        delivery: false,
        invoice: false,
        recipt_tax_invoice: false,
        credit_invoice: false,
        return_certificate: false,
        recipt: false,
        supplier_invoice: false
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(
        ".item-table div.v-data-table__wrapper"
      );
      this.$vuetify.goTo(newRow, { container: wrapper });
    },
    async deleteRow(index, item) {
      if (item.id) {
        await this.$store.dispatch('periodClosure/DeletePeriodClosureById', item.id)
        await this.$store.dispatch('periodClosure/GetPeriodClosure').then((res) => {
          if(res) {
            this.periodClosureItems = JSON.parse(JSON.stringify(res));
          }
        })
      } else {
        this.periodClosureItems.splice(index,1);
      }
    },
    async handelPeriodClosure() {
      if (this.$refs.formPeriodClousre.validate()) {
        this.loading = true;
        this.processedDocumentItems;
        const keys = Object.keys(this.periodClosureItems[0]);
        const list = this.processedDocumentItems.map((item) => {
          const filteredItem = {};
          delete item.untilDateMenu;
          delete item.fromDateMenu;
          delete item.yearDateMenu;
          for (const key in item) {
            if (key === "id" || keys.includes(key)) {
              filteredItem[key] = item[key];
            } else if (key === "formattedYear") {
              filteredItem["year"] = item[key];
            }
          }
          return filteredItem;
        });
        this.$store.dispatch('periodClosure/SetPeriodClosure', {data: list})
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>